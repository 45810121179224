import { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    return (window.location.href = `https://www.linkedin.com/company/breu-ai/`);
  }, []);
  return <></>;
};

export default AboutUs;
